  @mixin icon($class , $name) {
      .#{$class} {
          .icon {
              mask-image: url('../../Img/Icons/#{$name}.svg');
          }
      }
  }

  .prop-card-container {
      scroll-padding-top: 4em;
      scroll-behavior: smooth;
      height: 80vh;
      overflow-y: scroll;

      .prop-link {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .prop-card {
              background-color: white;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              border-radius: 10px;
              border: solid 1px #f0f0f0;
              height: 500px;
              width: 95%;
              margin: 1em 1em;
              transition: .5s;
              position: relative;

              button {
                  width: 90%;
                  background-color: $gray-450;
                  color: $gray-800;
                  visibility: visible;
                  margin-bottom: 1em;
              }

              &:hover {
                  button {
                      background-color: $blue-700;
                      color: white;
                  }
              }

              &.prop-invest {
                  margin: 1.5em;

                  .prop-title {
                      margin-bottom: 1em;
                  }
              }

              &.Pronto {
                  .prop-title {
                      .dark {

                          &:first-child {
                              height: 1.2em;
                              width: 30%;
                              margin-bottom: .5em;

                              &:last-child {
                                  width: 50%;
                                  height: .8em;
                              }
                          }
                      }

                  }

                  .prop-info {
                      display: flex;
                      flex-wrap: wrap;
                      flex-direction: column;
                      justify-content: space-evenly;
                      height: 20%;
                      white-space: nowrap;

                      .line {
                          display: flex;
                          justify-content: space-between;
                          width: 100%;

                          div:nth-child(even) {
                              background-color: #9D9D9D;
                              height: 1em;
                          }

                          .text:nth-child(odd) {
                              background-color: #B9B9B9;
                              height: 1em;
                          }

                          &:first-child .text {
                              width: 20%;
                          }

                          &:nth-child(2) .text {
                              width: 30%;
                          }

                          :nth-child(3) .text {
                              width: 40%;
                          }
                      }
                  }
              }

              &.Pronto span,
              &.Pronto .prop-info div,
              &.Pronto .progress-info div {
                  display: block;
                  border-radius: 10px;
                  height: .5em;
              }

              &.Pronto .dark,
              &.Pronto .progress-info div:nth-child(2) {
                  background-color: #9D9D9D;
                  width: 100%;
              }

              .tag {
                  position: absolute;
                  display: block;
                  padding: .3em 1.5em;
                  right: 6%;
                  top: 6%;
                  border-radius: 10px;
              }

              .prop-footer {
                  width: 80%;
                  margin-bottom: 10px;

                  @include icon("prop-baths", "bathroom");
                  @include icon("prop-rooms", "bed");
                  @include icon("prop-area", "area");

                  .icon {
                      mask-repeat: no-repeat;
                      mask-size: contain;
                      mask-position: center;
                      background-color: $blue-500;
                      height: 2em;
                      width: 2em;
                      margin-right: 0.7em;
                  }

                  p {
                      margin-bottom: 0;
                  }

                  div[class*="prop"] {
                      position: relative;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 0;
                  }

                  div[class*="prop"].lg {
                      width: 45%;
                  }

                  div[class*="prop"].sm {
                      width: 27.5%;
                  }
              }
          }
      }
  }

  .prop-card.Pronto .progress-bar,
  .prop-card.Pronto .progress-info div:first-child {
      background-color: #E6E6E6;
  }

  .prop-card.Pronto .progress {
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: #B9B9B9;
  }

  .prop-card.Pronto .progress-info div {
      width: 25% !important;
      height: .8em;
  }

  .prop-card .tag p {
      margin-bottom: 0;
      color: white;
  }

  .prop-card.Abierta .progress-bar .progress,
  .prop-card.Abierta .tag,
  .prop-link:hover .prop-card.Abierta button {
      background-color: var(--green);
  }

  .prop-card.Abierta button {
      color: var(--green);
  }

  .prop-card.Pronto .tag {
      background-color: #5394D0;
  }

  .prop-card.Cerrada .tag {
      background-color: var(--dark-purple);
  }

  .prop-card.Cerrada .progress-bar .progress {
      width: 100%;
  }

  .prop-card.active {
      -webkit-box-shadow: 0px 0px 0px 2px var(--light-purple);
      -moz-box-shadow: 0px 0px 0px 2px var(--light-purple);
      box-shadow: 0px 0px 0px 2px var(--light-purple);
  }





  .prop-link:hover .tag {
      color: white;
  }

  .prop-img {
      margin: 15px;
      border-radius: 5px;
      overflow: hidden;
      height: 210px;
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #D9D9D9;
      background-size: cover;
      background-position: center;
  }

  .prop-img img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: center;
  }

  .prop-title {
      width: 80%;
      font-weight: 600;
  }

  .prop-title h5 {
      margin-bottom: 0;
  }

  .prop-info {
      margin: 0 5px 20px 5px;
      width: 80%;
      align-self: end;
  }

  .prop-info .pago-title {
      margin-bottom: 0;
  }

  .prop-info p,
  .prop-title p {
      font-size: 14px;
      color: #8f8f8f;
      font-weight: 500;
      margin-bottom: 0;
  }

  .prop-info h5 {
      color: $blue-800;
      font-weight: 600;
  }