.results-col {
    position: relative;
    background: rgba(243, 244, 250, 0.56);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 2em;
    padding: 2em;

    .p-val {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: $blue-800;
        filter: brightness(80%);
    }

    .hidden {
        background: rgba(246, 246, 246, 0.5);
        position: relative;
    }

    .from-msg {
        color: $blue-800;
        font-weight: 400;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        text-align: center;
        opacity: 1 !important;
        background: rgba(246, 246, 246, 0.9);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 2% 5%;
    }

    .comp-data-bar .value {
        height: 30px;
        position: relative;
        top: -30px;
        width: 0%;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        background-color: $blue-500;
    }

    .comp-data-bar .background {
        height: inherit;
        background-color: $yellow-500;
        border-radius: 100px;
    }

    .comp-data-bar {
        width: 100%;
        height: 30px;
        margin: 22px 0;
    }

    .point {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 1em;
    }

    .rent .point {
        background-color: $yellow-500;
    }

    .cap-cont .point {
        background-color: $blue-500;
    }

    .rent,
    .cap-cont {
        display: flex;
        width: 90%;
        flex-wrap: nowrap;
        align-items: center;
    }

    .rent .point-t,
    .cap-cont .point-t {
        margin-bottom: 0px;
        color: #646464;
    }

    .rent .point-v,
    .cap-cont .point-v {
        margin-bottom: 0px;
        color: #646464;
        position: relative;
        margin-left: auto;
    }

    .cap-cont .point-info,
    .rent .point-info {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
    }

    .mortgage,
    .month-val {
        display: flex;
    }

    .mortgage .m-price {
        margin-left: auto;
        margin-bottom: 0px;
        position: relative;
    }

    .lgl {
        text-align: justify;
    }
}