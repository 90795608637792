        @mixin navlink {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            font-weight: 500;
            color: $blue-800;

            @include md {
                background-color: transparent;
            }

            p {
                margin-bottom: 0;
                font-size: 15px;
            }
        }

        .nav {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            padding: 0;
            top: 0;
            width: 100%;
            height: 87px;
            transition: 0.2s;
            z-index: 100;
            border: none;
            overflow-y: visible;
            padding: 12px 55px !important;
            background-color: #fff;

            @include xxl {
                width: 1440px;
            }

            .navbar-brand {
                display: block;
                height: 35px;
                width: 126px;
                padding: 0;

                .brand-image {
                    width: 100%;
                    height: 100%;
                    color: transparent !important;
                    mask-image: url(../../Img/logos/logoVF.svg);
                    mask-repeat: no-repeat;
                    mask-position: left;
                    mask-size: contain;
                    background-color: $blue-800;
                }
            }

            .navbar-center {
                display: none;
                margin: 0;
                height: 0;
                flex: 1 0 0;
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;
                transition: height .5s;
                background-color: transparent;
                gap: 62px;

                @include sm {
                    background-color: transparent;
                }

                @include l {
                    width: 1px;
                    position: relative;
                    overflow: hidden;
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    margin: auto;
                    height: min-content;
                    transition: 0.5s;

                    .nav-link {
                        color: $blue-800;
                        margin: 1em 0;
                        width: 100%;
                    }
                }

                .btn-rounded-green {
                    max-height: 50px;
                }

                .nav-link {
                    @include navlink
                }

            }

            .nav-link.active {
                &::after {
                    opacity: 1;
                }
            }

            .navbar-center.active {
                display: flex;
                width: 100%;
                position: fixed;
                align-items: center;
                height: 20%;
                top: 75px;
                background-color: $dark-purple;

                .nav-link {
                    margin: 0;
                    display: flex;
                    height: 33.3%;
                    flex-direction: column;
                    justify-content: space-around;
                    width: 100%;
                }
            }
        }

        .dropdown-menu {
            border: transparent;

            .dropdown-item {
                &:active {
                    background-color: var(--light-purple);
                }
            }

        }

        .navbar-toggler {
            display: block;
            margin-left: 1em;

            @include l {
                display: none;
            }
        }

        .navbar-toggler-icon {
            background-color: white;
            height: 2em;
            width: 2em;
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-image: url(../../Img/Icons/menu.svg);
            transition: 0.5s;
        }

        .navbar-toggler-icon.active {
            mask-image: url(../../Img/Icons/x.svg);
            mask-size: 70%;
        }

        .navbar-toggler {
            &:focus {
                box-shadow: none !important;
            }
        }

        .nav-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 15px;
            height: 100%;

            @include md {
                padding-right: 1em;
            }

            button {
                white-space: nowrap;
                padding: 8px 20px;

                @include md {
                    padding: .8em 2em;
                }
            }

            .nav-link {
                background: transparent;
                @include navlink;
            }
        }

        .nav-buttons.successpage {
            display: none;
        }

        .nav.invert {
            background-color: transparent;
            position: fixed;
            max-width: 100%;

            .brand-image {
                background-image: url(../../Img/logos/logowhite.png);
            }

            .navbar-toggler-icon {
                background-color: white;

                .navbar-center {
                    .nav-link {
                        color: white;

                        @include l {
                            margin: 1em 0;
                        }
                    }
                }
            }
        }

        .nav.nonavpage {
            display: none;
        }