.mapboxgl-marker svg path,
.mapboxgl-marker svg circle {
    transition: 0.2s;
}

.mapboxgl-marker svg path {
    fill: $blue-800;
}

.mapboxgl-marker:hover circle {
    fill: $blue-800;
}

.mapboxgl-marker:hover {
    cursor: pointer;
}

.mapboxgl-marker:hover path {
    fill: $blue-400;
}


.map-col {
    display: block;
}

.map-fullscreen .mapboxgl-map {
    display: block;
    height: 80vh !important;
    border-radius: 10px;
    margin-top: 6rem !important;
    margin-bottom: 3rem !important;
}

.mapboxgl-popup-content {
    max-width: 65%;
}