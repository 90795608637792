.sidebar-button span,
.filter-button span,
a[class^="carousel-control"] span {
    mask-image: url(../../Img/Icons/pointer.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    background-image: none;
}

.carousel-control-prev span {
    transform: rotate(180deg);
}

a[class^="carousel-control"] span {
    background-color: $gray-500;
}

.carousel-indicators {
    background-color: rgba(95, 95, 95, .5);
    padding: 0 1em;
    border-radius: 10px;
}

.carousel-indicators [data-bs-target] {
    background-color: white;
    opacity: 1;
}

.carousel-indicators .active {
    background-color: $green-400;
}

.invest-carousel .carousel-inner .carousel-item {
    background-color: white;
    height: 400px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.invest-carousel .carousel-indicators {
    display: none;
}

.carousel-inner .carousel-item {
    height: 70vh;
}

.carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}