h2 {
  &.blue {
    color: $blue-550;
  }
}

h5 {
  color: $blue-800;

  &.blue {
    color: $blue-550;
  }
}

h1,
h3,
h4,
h6 {
  color: $blue-800;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

a {
  &.blue {
    color: $blue-550;
    font-weight: bold;
  }
}

.dark-purple {
  color: $blue-800;
}