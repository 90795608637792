.TablePagination {
    display: block;
    margin-top: 1em;
  }

  .table-pagesIndexing {
    color: var(--dark-purple);
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }

  .navigationButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navigationButtonsContainer ul {
    background-color: var(--modal-color);
    font-weight: bold;
    color: var(--dark-purple);
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    list-style: none;
  }

  .navigationButtonsContainer button {
    padding: 1em;
  }

  .navigationButtonsContainer button:disabled {
    background-color: var(--light-blue);
    opacity: 0.5;
  }

  .navigationButtonsContainer button.prev {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .navigationButtonsContainer button.next {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }