.btn-rounded-invert {
    background-color: transparent;
    border: solid 1px $green-500;
    color: $green-500;

    &:hover {
        background-color: $green-500;
        color: $blue-800;
    }

    &.blue {
        border: solid 1px $blue-800;
        color: $blue-800;

        &:hover {
            background-color: $blue-800;
            color: $green-500;
        }
    }
}

.btn-rounded-green {
    background-color: $green-500;
    border: solid 1px transparent;
    color: $blue-800;

    &:hover {
        color: $blue-800;
        background-color: #A2F8FB;
    }
}

.btn-rounded-blue {
    background-color: $blue-800;
    color: $green-500;
}

.btn-transparent-grey {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: $gray-800;
    font-size: 14px;
    transition: opacity .5;

    &:hover {
        color: $blue-800;

        .icon {
            background-color: $blue-800;
        }
    }

    &:disabled {
        opacity: 0;
    }
}

.prop-invest-card {
    button {

        width: 90%;
    }
}

.google-btn {
    display: inline-block;
    background-color: #ffffff;
    color: black;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    border: solid 2px $blue-800;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    transition: .2s;

    span {
        font-size: 16px;
        font-weight: bold;
    }

    .google-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        width: 20px;
        height: 20px;
    }

}

.whatsapp {
    background-color: $wp-green;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    border-radius: 50%;
    width: 6em;
    height: 6em;
    bottom: 5%;
    right: 2%;
    border: solid 1px $green-500;
    z-index: 1000;

    .icon {
        background-color: white;
        width: 3em;
        height: 3em;
        mask-image: url(../../Img/Icons/whatsapp.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
    }

    &:hover {
        background-color: #2e9168;
        ;
        width: 6.5em;
        height: 6.5em;

        .icon {
            background-color: white;
        }
    }
}

.whatsapp,
.whatsapp .icon {
    transition: 0.2s;
}

.btn-rounded-results {
    background-color: $green-500;
    color: $blue-800;

    &:hover {
        background-color: $blue-800;
        color: $green-500;
    }
}

.calc-btn {
    width: 100% !important;
}