@import 'land-form-control';

.rounded-input {
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    border: solid 1px $gray-500;
    padding: 10px 15px;
    width: 100%;
    background-color: $gray300;
}

.form-check-input[type="radio"] {
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 0;
    height: 1em;
    width: 1em;
}

select.rounded-input {
    padding: 1em;
    display: flex;
    color: $blue-800;
}

label:hover,
input:hover {
    cursor: pointer;
}

.form-check label {
    font-weight: bold;
    color: rgb(0, 0, 0, 0.5);
}

.form-check-input:checked {
    background-color: $green-400;
    border-color: $green-400;
}

/* input.imagefile {

    display: none;
  } */

input.imagefile:hover+* {
    border-color: $blue-500;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        rgba(7, 66, 132, 0.16) 0px 0px 0px 1px,
        rgba(7, 66, 132, 0.08) 0px 3px 9px 0px,
        rgba(7, 66, 132, 0.08) 0px 2px 5px 0px
}

input.imagefile+label>span::after {
    content: "+ Añadir nueva imagen";
    font-size: 20px;
    height: 2em;
    color: $blue-400;
}

input.imagefile+label {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 1px $gray-500;
}