  .land-form-control {
      background-color: #f3f4fa;
      width: 100%;
      margin: 1em 0;
      border-radius: 10px;
      border: solid 1px transparent;
      padding: 10px;
      font-weight: 400;
      font-size: 14px;
      color: $gray-800;
      box-shadow: 0 0 0 0 transparent !important;
      &.full{
        color: black;
        background-color: white;
        border: 1px solid $blue-800;
      }
  }
  .land-form-control:focus {
      border: solid 1px $green-400;
  }

  .error-msg {
    color: #d8000c;
    margin-top: 2px;
  }

  .success-msg {
    color: #4fd960;
    margin-top: 2px;
  }