@mixin xs {
    @media only screen and (max-width:412px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (max-width:810px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width:810px) {
        @content;
    }
}

@mixin l {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: 1224px) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width:1500px) {
        @content
    }
}