.ammenities .icon {
    height: 2em;
    width: 2em;
    margin-right: 2em;
    background-color: rgb(61, 206, 80);
    mask-image: url(../../Img/Icons/check.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
}

.ammenities .ammenitie {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
    margin: 1em 0;
}

.ammenities .row p {
    margin-bottom: 0;
    font-size: medium;
}