$dark-purple: #280541;
$light-purple: #4849A0;
$blue: #045aaf;
$light-blue: #78C1DD;
$cream: #e5f2fe;
$gray: #8c8c8c;
$light-gray: #f6f6f6;
$green: #00C1AF;
$light-green: #A3EBEE;
$danger: #d07915;
$modal-color: rgba(243, 244, 250, 0.56);

$purple-800: #280541;
$purple-400: #4849A0;
$blue-800: #001845;
$blue-700: #1976d2;
$blue-550: #0466C8;
$blue-500: #5394D0;

$blue-400: #78C1DD;
$blue-600: #386075;
$blue-200: #e5f2fe;
$gray-800: #9E9E9E;
$gray-500: #B0BAC9;
$gray-450: #C9CFDA;
$gray-400: #f5f5f5;
$gray300: #F3F4FA;
$green-500: #6afafb;
$green-400: #A3EBEE;
$yellow-500: #FFC93D;
$wp-green:#52CC99;