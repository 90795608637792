@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-Regular.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-Bold.otf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-BoldItalic.otf);
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-Italic.otf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-Light.otf);
    font-weight: light;
    font-style: normal;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-LightItalic.otf);
    font-weight: light;
    font-style: italic;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-Medium.otf);
    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family: "NeueMontreal";
    src: url(../fonts/NeueMontreal-MediumItalic.otf);
    font-weight: medium;
    font-style: italic;
}

/* Satoshi Font Faces */
@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-Regular.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-Medium.otf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-Bold.otf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-Light.otf);
    font-weight: 300;
    /* Light */
    font-style: normal;
}

@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-LightItalic.otf);
    font-weight: 300;
    /* Light */
    font-style: italic;
}

@font-face {
    font-family: "Satoshi";
    src: url(../fonts/satoshi/Satoshi-Italic.otf);
    font-weight: 400;
    /* Regular */
    font-style: italic;
}


/* Switzer Font Faces */
@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-Regular.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-Medium.otf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-Bold.otf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-Light.otf);
    font-weight: 300;
    /* Light */
    font-style: normal;
}

@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-LightItalic.otf);
    font-weight: 300;
    /* Light */
    font-style: italic;
}

@font-face {
    font-family: "Switzer";
    src: url(../fonts/switzer/Switzer-Italic.otf);
    font-weight: 400;
    /* Regular */
    font-style: italic;
}


* {
    font-family: "Satoshi", sans-serif;
    margin: 0;
    padding: 0;
    font-size: small;
}

.montserrat {
    font-family: "Montserrat", sans-serif;
}



a {
    text-decoration: none !important;
}

@include sm {
    .container {
        width: 95vw;
    }

    .d-sm-none {
        display: none !important;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: var(--gray);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--light-purple);
}

::-webkit-scrollbar-track:hover {

    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
    opacity: 0;
    border: 0px none transparent;
    border-radius: 50px;
}

::-webkit-scrollbar-track:active {
    background: var(--cream);
}

::-webkit-scrollbar-corner {
    background: transparent;
}

