  .prop-img-big {
      height: 400px;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
  }

  .prop-img-big img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
  }

  .simulator .header,
  .simulator .content {
      padding: 2em;
  }

  .simulator .header {
      border-radius: 10px 10px 0 0;
      background-color: #f5f5f5;
      border-bottom: transparent;
  }

  .simulator .content {
      background-color: white;
      border-radius: 10px;
  }

  .simulator .content input[type="text"]::placeholder,
  .simulator .content input[type="text"] {
      color: $gray-500;
  }

  .simulator .content button {
      width: 100%;
  }

  .agent-card {
      height: 400px;
      border-radius: 10px;
      border: 2px solid rgb(235, 235, 235);
  }

  .agent-info {
      background: white;
      height: 250px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
  }

  .agent-info {
      .text {
          width: 100%;
      }

      .gray {
          font-size: 14px;
      }

      .black {
          font-size: 18px;
      }

      h5 {
          width: 100%;
          margin-bottom: 0.5em;
          text-align: center;
      }
  }

  .agent-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      margin-bottom: 1em;

      img {
          max-width: 100%;
      }
  }

  .agent-footer {
      position: relative;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      height: 150px;
      background-color: rgba(240, 240, 240);
      justify-content: center;
      align-content: space-around;
      border-bottom-right-radius: 10px;

      button {
          height: 54px;
          margin: 0%;
          width: 100%;
      }
  }

  @include sm {
      .prop-card-container {
          height: auto;
      }

      .prop-img-big {
          height: auto;
          margin-bottom: 15px;
      }

      .prop-card {
          width: 100%;
      }

      .prop-img {
          width: 100%;
      }

      .prop-img img {
          width: 100%;
      }
  }