  .info-lg .icon {
      position: relative;
      height: 50px;
      width: 50%;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      background-color: $blue-800;
  }

  .info-lg.ammenitie {
      padding: 0 1em;
  }

  .info-lg .row {
      margin: 0;
      flex-wrap: nowrap;
      align-items: center;
      max-width: 100%;
  }

  .info-lg .row .col-sm-3 {
      width: 25%;
      display: flex;
      justify-content: center;
      padding: 0;
  }

  .info-lg .row .col-sm-9 {
      width: 75%;
  }

  .info-lg .row p {
      margin-bottom: 0;
  }

  .info-lg {
      padding: .5em 0;
      height: 70px;
  }

  .info-lg,
  .info-financial-card {
      border: solid 2px rgb(240, 240, 240);
      background-color: white;
      border-radius: 10px;
      margin: 10px 0;

  }

  .info-lg .row {
      align-items: center;
      height: 100%;
      width: 100%;
  }

  .info-lg .monthly .icon {
      mask-image: url(../../Img/Icons/label.svg);
  }

  .info-lg .term .icon {
      mask-image: url(../../Img/Icons/calendar.svg);
  }

  .info-lg .ownership .icon {
      mask-image: url(../../Img/Icons/target.svg);
  }

  .info-lg .total .icon {
      mask-image: url(../../Img/Icons/coins.svg);
  }

  .info-lg .administration_fee .icon {
      mask-image: url(../../Img/Icons/administration_cost.svg);
  }

  .prop-footer .prop-baths .icon,
  .info-lg .bathrooms .icon {
      mask-image: url(../../Img/Icons/bathroom.svg);
  }

  .prop-footer .prop-rooms .icon,
  .info-lg .rooms .icon {
      mask-image: url(../../Img/Icons/bed.svg);
  }

  .prop-footer .prop-area .icon,
  .info-lg .area .icon {
      mask-image: url(../../Img/Icons/area.svg);
  }

  .prop-footer .prop-parking .icon,
  .info-lg .parking .icon {
      mask-image: url(../../Img/Icons/parking.svg);
  }

  .prop-footer .prop-livingarea .icon,
  .info-lg .livingarea .icon {
      mask-image: url(../../Img/Icons/area.svg);
  }

  .prop-footer .prop-livingarea .icon,
  .info-lg .livingarea .icon {
      mask-image: url(../../Img/Icons/area.svg);
  }

  .prop-footer .economic_class .icon,
  .info-lg .economic_class .icon {
      mask-image: url(../../Img/Icons/economic_class.svg);
  }

  .prop-footer .economic_clasification .icon,
  .info-lg .economic_clasification .icon {
      mask-image: url(../../Img/Icons/economic_class.svg);
  }

  .prop-footer .admin-cost .icon,
  .info-lg .admin-cost .icon {
      mask-image: url(../../Img/Icons/administration_cost.svg);
  }

  .prop-footer .prop-type .icon,
  .info-lg .prop-type .icon {
      mask-image: url(../../Img/Icons/prop_type.svg);
  }

  .prop-footer .antiquity .icon,
  .info-lg .antiquity .icon {
      mask-image: url(../../Img/Icons/antiquity.svg);
  }

  .prop-footer .floor .icon,
  .info-lg .floor .icon {
      mask-image: url(../../Img/Icons/floor.svg);
  }

  .prop-footer .min_down_payment .icon,
  .info-lg .min_down_payment .icon {
      mask-image: url(../../Img/Icons/housing.svg);
  }