@mixin media($name) {
    .bg {
        .icon {
            mask-image: url('../../Img/Icons/#{$name}.svg');
        }
    }
}

.footer {
    padding: 30px 80px;
    background-color: $blue-800 ;
    align-items: center;
    max-width: 100vw;
    justify-content: center;
    margin-top: 54px;

    * {
        font-size: 16px !important;
    }

    @include md {
        margin-top: 173px;
        display: flex;
    }

    @include xxl {
        padding: 42px 0 0;

    }

    .footer-container {
        width: 100%;
        display: flex;
        gap: 0 40px;
        align-items: flex-start;
        flex-wrap: wrap;

        @include l {
            gap: 40px 0;
        }

        @include xxl {
            width: 1440px;
            justify-content: flex-end;
        }

        a {
            &:hover {

                p,
                h5 {
                    color: $green-500;
                }
            }
        }

        .info {
            width: 100%;

            @include l {

                &:first-child {
                    width: 100%;
                }

                width: 33%;
            }

            @include xl {
                width: 200px;

                &:first-child {
                    margin-right: auto;
                    width: 289px;
                }

                &:last-child {
                    width: 25%;
                }
            }

            .icon {
                mask-image: url(../../Img/logos/logoVF.svg);
                mask-position: left;
                mask-size: contain;
                mask-repeat: no-repeat;
                background-color: white;
                height: 3em;
            }

            .icon.logo {
                height: 40px;
                width: 100%;
                margin-bottom: 25px;

            }

            p {
                font-weight: normal;
                color: white;
                margin-bottom: 0;
                margin-bottom: 15px;
                max-width: 90%;
                text-wrap: pretty;

                @include md {
                    margin-top: 0;
                }
            }

            .title {
                color: white;
                width: 100%;
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 41px;

            }

        }

        .info.media {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            a {
                color: white;
                width: 100%;

                .media-item {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;

                    .bg {
                        background-color: $green-500;
                        border-radius: 50%;
                        width: 38px;
                        height: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;

                        .icon {
                            background-color: $blue-800;
                            height: 20px;
                            width: 20px;
                            mask-position: center;
                            mask-repeat: no-repeat;
                            mask-size: contain;
                            transition: .2s;
                            margin: 0;
                        }
                    }

                    .text-container {
                        width: 80%;

                        .title {
                            width: 100%;
                            font-family: "Switzer", sans-serif;
                            font-weight: 800;
                            margin-bottom: 0;
                        }

                        .text {
                            width: 100%;
                            font-family: "Switzer",
                                sans-serif;
                        }
                    }

                    &:hover {
                        .bg {
                            background-color: $blue-800;
                            border: solid 1px $green-500;

                            .icon {
                                background-color: $green-500;
                            }
                        }

                        .title {
                            color: $green-500;
                        }
                    }
                }
            }

            a.whatsappfoot {
                @include media('phone')
            }

            a.emailfoot {
                @include media('inbox')
            }

            a.addressfoot {
                @include media('location')
            }

        }

        .copyright {
            display: flex;
            justify-content: center;
            border-top: solid 1px rgba($color: $blue-600, $alpha: 1);
            padding: 20px;
            width: 100%;
            color: #f2f2f2;
            margin: 0;
        }
    }
}