  .filter-container {
      position: relative;
      height: 95vh;
      overflow-y: scroll;

      .filter-title {
          margin-top: 3rem;
      }

      .filter-button,
      .sidebar-button {
          display: none;
      }
  }

  @include sm {

      .filter-button,
      .sidebar-button {
          font-weight: bold;
          color: white;
          top: 50%;
          background-color: $blue-800;
          border: none;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: fixed;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3em;
          left: 0;
          height: 3em;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          transition: 0.5s;
          z-index: 11;
      }

      .filter-button span,
      .sidebar-button span {
          height: 1em;
          width: 1em;
          background-color: white;
          transition: .5s;
      }

      .filter-button span {
          mask-image: url(../../Img/Icons/filters.svg);
          mask-size: cover;
      }

      .invest-modal .modal-dialog,
      .invest-modal.tutorial .modal-dialog {
          width: 95%;
      }

      .invest-modal .modal-header {
          height: 150px;
      }

      .invest-modal .modal-footer p {
          margin-bottom: 1.5em;
      }

      .sidebar-button.active {
          left: 80%;
      }

      .filter-button.active {
          left: 90%;
      }

      .sidebar-button.active span,
      .filter-button.active span {
          transform: rotate(180deg);
      }

      .sidebar,
      .filter-container {
          position: fixed;
          z-index: 10;
          transition: 0.5s;
      }

      .sidebar {
          width: 80%;
          left: -80%;
      }

      .sidebar.active {
          left: 0;
      }

      .filter-container {
          left: -90%;
          height: 92vh;
          padding: 3em 2em;
          width: 90%;
          display: flex;
          align-items: flex-start;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
      }

      .filter-container .filter-title {
          margin-top: 0;
      }

      .filter-container.active {
          left: 0;
          overflow-y: scroll;
      }
  }