  .invest-modal .modal-dialog {
      width: 50%;
  }

  .invest-modal.tutorial .modal-dialog {
      width: 30%;
  }

  .invest-modal.tutorial .modal-content {
      height: 600px;
  }

  .invest-modal .modal-header .btn-close,
  .invest-modal .modal-header .btn-back {
      position: absolute;
      background: none;
      background-color: white;
      mask-image: url(../../Img/Icons/close.svg);
      mask-size: contain;
      mask-position: center;
      top: 2.5em;
      width: 28px;
      height: 28px;
      right: 2.5em;
      border: none;
      border-radius: 0;
      padding: 0;
      opacity: .7;
  }

  .invest-modal .modal-header .btn-close:hover,
  .invest-modal .modal-header .btn-back:hover {
      opacity: 1;
  }

  .invest-modal .modal-header .btn-back {
      right: auto;
      left: 2.5em;
      mask-image: url(../../Img/Icons/back.svg);
  }

  .invest-modal .modal-content {
      background-color: $blue-800;
      border-radius: 20px;
      position: relative;
      padding: 0;
  }

  .invest-modal.tutorial .modal-header {
      height: 150px;
  }

  .invest-modal .modal-header,
  .invest-modal .modal-footer,
  .invest-modal .modal-body {
      border: none;
  }

  .invest-modal .modal-header {
      background-image: url(../../Img/modalHeader.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center 115%;
      background-color: $blue-400;
      display: flex;
      padding: 2em 0 0 3em;
      align-items: flex-start;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      height: 200px;
      width: 100%;
  }


  .invest-modal .modal-body {
      padding: 3em;
  }

  .invest-modal.tutorial .modal-body .tutorial-info {
      height: 150px;
  }

  .invest-modal.tutorial .modal-body {
      height: 350px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }

  .invest-modal .modal-body .step-selector {
      width: 100%;
      display: flex;
      position: absolute;
      bottom: 20px;
      left: 0;
      justify-content: center;
  }

  .invest-modal .modal-body .step-selector button {
      height: 1em;
      width: 1em;
      margin: 0 1em;
      border-radius: 50%;
      background-color: $gray-500;
      border: none;
      transition: .2s;
  }

  .invest-modal.tutorial .next-step {

      width: 50% !important;
  }

  .invest-modal .modal-body .step-selector button:hover,
  .invest-modal .modal-body .step-selector button.active {
      background-color: $blue-400;
  }

  .invest-modal .modal-body .icon {
      height: 100px;
      width: 100%;
      background-color: white;
      mask-position: center;
      mask-size: contain;
      mask-repeat: no-repeat;
      margin-bottom: 1.5em;
  }

  .invest-modal .modal-body .icon._0 {
      mask-image: url(../../Img/logos/logoVF.svg);
      mask-size: 40%;
  }

  .invest-modal .modal-body .icon._1 {
      mask-image: url(../../Img/Icons/oportunities.svg)
  }

  .invest-modal .modal-body .icon._2 {
      mask-image: url(../../Img/Icons/profit.svg)
  }

  .invest-modal .modal-body .icon._3 {
      mask-image: url(../../Img/Icons/housing.svg)
  }

  .invest-modal .modal-body .btn-rounded-blue {
      font-weight: 600;
      padding: 1em 1em;
      width: 100%;
  }

  .invest-modal .modal-body h3,
  .invest-modal .modal-body p,
  .invest-modal .modal-footer * {
      color: white;
  }

  .invest-modal .modal-footer {
      padding: 0 3em 3em;
  }

  .invest-modal .modal-footer p {
      margin: 0;
  }

  .modal-backdrop {
      height: 150vh;
      right: 0;
      bottom: 0;
  }

  .modal {
      width: 100vw;
      position: fixed;
  }

  .modal-title,
  .modal-header .filter,
  .modal-header h5 {
      margin-bottom: 1em;
  }

  .modal-header {
      width: 100%;
  }

  .modal-header {
      flex-wrap: wrap;
      position: relative;
  }

  .modal-header .btn-close {
      position: absolute;
      top: 1em;
      right: 1em;
  }

  .modal-header .inicial,
  .modal-header .adicional {
      width: 40%;
      padding: 0 5% 0 0;
      display: flex;
      align-items: center;
  }

  .modal-header .filter {
      display: flex;
      width: 100%;
      padding: 1em 0;
      background-color: #f5f5f5;
      border: 1px solid $gray-450;
      border-radius: 10px;
  }

  .modal-header .btn-rounded-blue {
      width: 18%;
  }

  .modal-header .inicial p,
  .modal-header .adicional p {
      width: 60%;
      margin-bottom: 0;
      text-align: center;
  }

  .modal-header .result {
      background-color: rgba(243, 244, 250, 0.56);
      display: flex;
      justify-content: flex-start;
      border-radius: 10px;
      flex-wrap: wrap;
      padding: 1em 3em;
  }

  .modal-header .result .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1em 1em;
  }

  .info .icon,
  .modal-header .result .block .icon {
      background-color: $gray-500;
      mask-repeat: no-repeat;
      mask-image: url(../../Img/Icons/info.svg);
      mask-size: contain;
      mask-position: center;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
  }

  .modal-header .result .block p {
      color: $blue-800;
      margin-bottom: 0;
  }

  .modal-header .h4,
  .modal-header h5 {
      width: 100%;
  }

  .modal-header .result {
      width: 100%;
  }

  .modal-header .result .title {
      margin-right: 1em;
  }

  .modal-dialog {
      max-width: 80vw;
  }

  .modal-content {
      padding: 1.5em;
  }

  .modal-body table {
      width: 100%;
      text-align: center;
      border-radius: 1em;
      background-color: #fff;
      table-layout: fixed;
  }

  .modal-body table tbody tr.table-footer td {
      color: $blue-800;
      font-weight: bold;
      background-color: rgba(243, 244, 250, 0.56);
  }

  .modal-body table tbody tr td {
      padding: 0.7em 0;
  }

  .modal-body table thead th,
  .modal-body table tbody tr td {
      border: solid 2px rgba(243, 244, 250, 0.56);
  }

  .modal-body table thead th {
      color: $blue-800;
      background-color: rgba(243, 244, 250, 0.56);
      padding: 1em 0;
  }

  .modal-footer {
      justify-content: flex-start;
  }

  @include sm {
      .modal-dialog {
          max-width: 100vw;
      }

      .modal-content {
          padding: 0.8em;
      }

      .modal-header {
          padding: 0;
      }

      .modal-header .inicial,
      .modal-header .adicional,
      .modal-header .btn-rounded-blue {
          width: 100%;
          margin: 1em 0;
      }

      .modal-header .filter {
          flex-wrap: wrap;
      }

      .modal-header .btn-rounded-blue {
          margin: 1em 1em;
      }

      .modal-header .result {
          padding: 0.5em 0.5em;
          display: block;
      }

      .modal-header .result .title {
          font-size: 1em;
          width: 50%;
      }

      .modal-dialog {
          max-width: auto;
      }

      .modal-header .result .block * {
          font-size: 14px;
      }

      .modal-header .result .data {
          width: 50%;
      }

      .modal-body {
          padding: 1rem 0;
      }

  }