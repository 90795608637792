.mform-container {
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  height: 100vh;

  @include sm {
    margin-bottom: 100px;
  }

  .content {
    width: 100%;
  }

  .header {
    margin: 0;
    background-image: url(../../Img/form.jpg);
    background-position: center 15%;
    background-size: cover;
    height: 30vh;
    width: 100%;

    .sellpage {
      background-image: url(../../Img/pred.jpg);
      background-position: center;
    }
  }

  .logo {
    background-image: url(../../Img/logos/logo-toperty.jpg);
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-top: -60px;
    height: 120px;
    width: 120px;
  }

  input,
  select {
    max-width: 60%;
  }
}

.job-box {
  margin: .3em 0;
  display: flex;
  align-items: center;
}

.job-box label {
  max-width: 80%;
}