  @import './btn';

  button {
      border: transparent
  }

  button[class^="btn-rounded"],
  input[class^="btn-rounded"],
  a[class^="btn-rounded"] {
      padding: .8em 3em;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      transition: 0.2s;
  }

  .prop-types-list {
      li {
          list-style-type: none;
      }
  }