.calc-cards-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 1em 0;
  }

  .calc-cards-container .calc-card {
    display: inline-block;
    width: 300px;
    margin-right: 1em;
  }

  .calc-card .card-title {
    background-color: $modal-color;
    padding: 1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .calc-card .body {
    padding: 1em;
    border: solid 2px $modal-color;
  }

  .card-title {
    margin-bottom: 0;
  }

  .calc-card .data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5em 0;
    width: 100%;
  }

  .calc-card .title,
  .calc-card .info {
    margin-bottom: 0;
    width: 30%;
  }

  .calc-card .title,
  .calc-card .card-title {
    font-weight: bold;
  }